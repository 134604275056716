import Decimal from 'decimal.js'
import * as React from 'react'
import { Key } from 'ts-key-enum'

export const sumDecimalArray = (decimals: Decimal[]): Decimal =>
  decimals.reduce((prev: Decimal, current: Decimal) => prev.add(current), new Decimal(0))

export const sortByPosition = <T extends { position: number }>(elements: T[]): T[] =>
  elements.sort((a, b) => a.position - b.position)

export const asDecimal = (value: string | null | number | undefined | Decimal): Decimal =>
  new Decimal(value === 'None' ? 0 : value || 0)

export const handleKeyDown =
  (key: Key, action: (...params) => void) =>
  (event: React.KeyboardEvent): void => {
    if (event.key !== key) return

    event.preventDefault()
    action(event)
  }

export const formatPhoneNumber = (phoneNumber: string | null | undefined): string => {
  if (!phoneNumber) return ''

  let cleaned = phoneNumber.replace(/\D/g, '')
  let formatted = ''

  if (cleaned.startsWith('+48')) {
    cleaned = cleaned.substring(3)
    formatted = '+48 '
  } else if (cleaned.startsWith('48')) {
    cleaned = cleaned.substring(2)
    formatted = '+48 '
  }

  const groups = cleaned.match(/.{1,3}/g)
  if (groups) {
    formatted += groups.join(' ')
  }

  return formatted
}

export const formatToWordsWithUppercaseLetterStart = (sentence: string): string => {
  const split = sentence.split(' ')
  return split.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
}
